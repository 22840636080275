
<template>
  <base-section
    id="info-alt"
    space="56"
  >
    <v-container>
      <v-row justify="space-between">
        <v-tabs
          v-model="tab"
          show-arrows
          background-color="secondary accent-4"
          icons-and-text
          dark
          grow
        >
          <v-tabs-slider color="primary accent-4" />
          <v-tab
            v-for="(i, idx) in tabs"
            :key="idx"
          >
            <v-icon>
              {{ i.icon }}
            </v-icon>
            <div class="caption py-1">
              {{ $t(`sections.customers.${i.name}.title`) }}
            </div>
          </v-tab>
          <v-tab-item>
            <v-card
              outlined
              class="px-4"
            >
              <v-card-text>
                <v-form
                  ref="loginForm"
                  v-model="loginFormvalid"
                  lazy-validation
                >
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="loginEmail"
                        :rules="loginEmailRules"
                        :label="$t('sections.customers.login.email')"
                        required
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="loginPassword"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        :label="$t('sections.customers.login.password')"
                        :hint="$t('sections.customers.errors.minchar')"
                        counter
                        @click:append="show1 = !show1"
                      />
                    </v-col>
                    <v-col
                      class="d-flex xsm"
                      cols="12"
                      sm="6"
                    />
                    <v-spacer />
                    <v-col
                      class="d-flex xsm align-end"
                      cols="12"
                      sm="3"
                    >
                      <v-btn
                        x-large
                        block
                        :disabled="!loginFormvalid || loading"
                        :loading="loading"
                        color="secondary"
                        @click="login"
                      >
                        {{ $t('sections.customers.login.btn') }}
                      </v-btn>
                    </v-col>
                    <v-col>
                      <v-btn
                        x-large
                        block
                        color="primary"
                        text
                        :href="getResetPwUrl()"
                      >
                        {{ $t('sections.customers.resetpw.title') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card
              class="px-4"
              outlined
            >
              <v-card-text>
                <v-form
                  ref="registerForm"
                  v-model="registerFormvalid"
                  lazy-validation
                >
                  <v-row>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="firstName"
                        :rules="[rules.required]"
                        :label="$t('sections.customers.register.firstname')"
                        maxlength="20"
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="lastName"
                        :rules="[rules.required]"
                        :label="$t('sections.customers.register.lastname')"
                        maxlength="20"
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="companyName"
                        :rules="[rules.required]"
                        :label="$t('sections.customers.register.companyname')"
                        maxlength="20"
                        required
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="6"
                      md="6"
                    >
                      <v-text-field
                        v-model="email"
                        :rules="emailRules"
                        :label="$t('sections.customers.register.email')"
                        required
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        :label="$t('sections.customers.register.password')"
                        :hint="$t('sections.customers.errors.minchar')"
                        counter
                        @click:append="show1 = !show1"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="verify"
                        block
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, passwordMatch]"
                        :type="show1 ? 'text' : 'password'"
                        name="input-10-1"
                        :label="$t('sections.customers.register.pwconfirmation')"
                        counter
                        @click:append="show1 = !show1"
                      />
                    </v-col>
                    <v-spacer />
                    <v-col
                      class="d-flex ml-auto xsm"
                      cols="12"
                      sm="3"
                    >
                      <v-btn
                        x-large
                        block
                        :loading="loading"
                        :disabled="!registerFormvalid || loading"
                        color="secondary"
                        @click="register"
                      >
                        {{ $t('sections.customers.register.btn') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card
              class="px-4"
              outlined
            >
              <v-card-text>
                <v-form
                  v-if="!emailpwsent"
                  ref="resetForm"
                  v-model="resetpwFormvalid"
                  lazy-validation
                >
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="email"
                        :rules="emailRules"
                        :label="$t('sections.customers.resetpw.email')"
                        required
                      />
                    </v-col>
                    <v-spacer />
                    <v-col
                      class="d-flex ml-auto xsm"
                      cols="12"
                      sm="3"
                    >
                      <v-btn
                        x-large
                        block
                        :loading="loading"
                        :disabled="!resetpwFormvalid || loading"
                        color="secondary"
                        @click="resetPw"
                      >
                        {{ $t('sections.customers.resetpw.btn') }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-form>
                <p v-else>
                  {{ $t('sections.customers.resetpw.pwemailsent') }}
                </p>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  import axios from 'axios'
  export default {
    name: 'SectionCustomers',
    data: () => ({
      dialog: true,
      tab: 0,
      tabs: [
        { name: 'login', icon: 'mdi-login' },
        { name: 'register', icon: 'mdi-clipboard-account' },
        // { name: 'resetpw', icon: 'mdi-lock-alert' },
      ],
      loginFormvalid: true,
      registerFormvalid: true,
      resetpwFormvalid: true,
      loading: false,

      firstName: '',
      lastName: '',
      email: '',
      password: '',
      verify: '',
      loginPassword: '',
      loginEmail: '',
      companyName: '',

      show1: false,
      emailpwsent: false,
    //   rules: {
    //     required: value => !!value || 'Required.',
    //     min: v => (v && v.length >= 8) || 'Min 8 characters',
    //   },
    }),
    computed: {
      passwordMatch () {
        return () => this.password === this.verify || this.$t('sections.customers.errors.pwmatch')
      },
      loginEmailRules () {
        return [
          v => !!v || this.$t('sections.customers.errors.required'),
          // v => /.+@.+\..+/.test(v) || this.$t('sections.customers.errors.validemail'),
        ]
      },
      emailRules () {
        return [
          v => !!v || this.$t('sections.customers.errors.required'),
          v => /.+@.+\..+/.test(v) || this.$t('sections.customers.errors.validemail'),
        ]
      },
      rules () {
        return {
          required: value => !!value || this.$t('sections.customers.errors.required'),
          min: v => (v && v.length >= 8) || this.$t('sections.customers.errors.minchar'),
        }
      },
    },
    methods: {
      login () {
        if (this.$refs.loginForm.validate()) {
          this.loading = true
          // submit form to server/API here...
          console.log('try to login')
          axios
            .post(process.env.VUE_APP_API_URL + 'auth/login', {
              crossDomain: true,
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
              },
              username: this.loginEmail,
              password: this.loginPassword,
            })
            .then(response => {
              this.loading = false
              window.location.href = `${process.env.VUE_APP_CONSOLE_URL}#/dashboard?token=${response.data.token}&language=${response.data.language}&numberline="${response.data.options.numberLine}"`
              // return response.data
            })
            .catch(err => {
              this.loading = false
              console.error(err)
            })
        }
      },
      register () {
        if (this.$refs.registerForm.validate()) {
          // submit form to server/API here...
          this.loading = true
          axios
            .post(process.env.VUE_APP_API_URL + 'auth/register', {
              crossDomain: true,
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
              },
              data: {
                user: {
                  email: this.email,
                  password: this.password,
                  lastname: this.lastName,
                  firstname: this.firstName,
                  companyName: this.companyName,
                },
              },
            })
            .then(response => {
              this.loading = false
              console.log('response', response)
              window.location.href = `${process.env.VUE_APP_CONSOLE_URL}#/dashboard?token=${response.data.token}&language=${response.data.language}&numberline="${response.data.options.numberLine}"`
              // return response.data
            })
            .catch(err => {
              this.loading = false
              console.error(err)
            })
        }
      },
      resetPw () {
        if (this.$refs.resetForm.validate()) {
          // submit form to server/API here...
          console.log('try to Reset PW')
          this.loading = true
          axios
            .post(process.env.VUE_APP_API_URL + 'auth/resetpw', {
              crossDomain: true,
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
              },
              data: {
                user: {
                  email: this.email,
                },
              },
            })
            .then(response => {
              this.loading = false
              console.log('response', response)
              if (response.status === 200 && response.data && response.data.success) {
                // display a generic message saying email was sent if it exist
                this.emailpwsent = true
              }
              // return response.data
            })
            .catch(err => {
              this.loading = false
              console.error(err)
            })
        }
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      getResetPwUrl () {
        return `${process.env.VUE_APP_CONSOLE_URL}#/resetpw?resetpw=1`
      },
    },
  }
</script>

<style>
  .custom-loader {
    animation: loader 1s infinite;
    display: flex;
  }
  @-moz-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-o-keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @keyframes loader {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
</style>
